

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { CustomDate } from "@/mixins/custom-date";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { VueEditor } from "vue2-editor";
import VueCountryCode from "vue-country-code";
import { SystemError } from "@/models/system-error.interface";
import ColorPicker from "@/components/Input/ColorPicker.vue";
import info from "@/utils/info";
import { EditorImages } from "@/mixins/editor-images";
import { FileCheck } from "@/mixins/file-check";
import { StyleCheck } from "@/mixins/style-check";
import LocaleSwitcher from "@/components/General/LocaleSwitcher.vue";
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    VueEditor,
    VueCountryCode,
    ColorPicker,
    LocaleSwitcher,
    Carousel,
    Slide,
  },
})
export default class FistSteps extends mixins(
  FormValidations,
  Navigation,
  CustomDate,
  EditorImages,
  FileCheck,
  StyleCheck
) {
  step = 1;
  showErrorDescription = false;
  showErrorPassword = true;
  showPasswordCheck = false;
  showPassword = false;
  testContrast = false;
  loading = false;
  minLengthPassword = process.env.VUE_APP_MIN_LENGTH_PASSWORD;
  mode = "LIGHT";
  phoneiso2: string | undefined = "";

  phoneCountry: { dialCode: string; iso2: string } = { dialCode: "", iso2: "" };
  companyPhoneCountry: { dialCode: string; iso2: string } = {
    dialCode: "",
    iso2: "",
  };
  formatedPhone = "";
  companyPhone = "";
  company = {
    identification: "",
    name: "",
    title: "",
    description: "",
    email: "",
    phone: "",
    address: {
      primary_line: "",
      secondary_line: "",
      city: "",
      state: "",
      country: "",
      zip_code: "",
    },
  };

  user = {
    identification: "",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    phone: "",
    alias: "",
    email: "",
    password: "",
    address: {
      primary_line: "",
      secondary_line: "",
      city: "",
      state: "",
      country: "",
      zip_code: "",
    },
  };

  passwordValidations = {
    minLength: false,
    minSpecial: false,
    minNumber: false,
    minCapitalLetter: false,
    noEmptySpaces: false,
    equals: false,
  };

  system: {
    logo: { preview: string; file: File | null };
    styles: {
      colors: {
        light: { value: string; level: string }[];
        dark: { value: string; level: string }[];
      };
    };
    carousel: { files: Array<File>; filesPreview: Array<string> };
  } = {
    logo: {
      preview: "",
      file: null,
    },
    styles: {
      colors: {
        light: [],
        dark: [],
      },
    },
    carousel: {
      files: [],
      filesPreview: [],
    },
  };

  systemSetUpLoading = {
    company: {
      check: false,
      loading: false,
    },
    styles: {
      check: false,
      loading: false,
    },
    images: {
      check: false,
      loading: false,
    },
  };

  verifyUserPassword = "";

  $refs!: {
    firstStepForm: HTMLFormElement;
    secondStepForm: HTMLFormElement;
    thirdStepForm: HTMLFormElement;
    fourthStepForm: HTMLFormElement;
  };

  @Watch("company.description")
  checkCompanyDescription(): void {
    if (this.company.description != "") {
      this.showErrorDescription = false;
    } else {
      this.showErrorDescription = true;
    }
  }

  @Watch("user.phone")
  setUserPhone(phone: string): void {
    const clearPhone = phone != null ? phone : "";
    this.formatedPhone =
      "+" +
      this.phoneCountry.dialCode +
      " " +
      clearPhone +
      " (" +
      this.phoneCountry.iso2 +
      ")";
  }
  @Watch("company.phone")
  setCompanyPhone(phone: string): void {
    const clearPhone = phone != null ? phone : "";
    this.companyPhone =
      "+" +
      this.companyPhoneCountry.dialCode +
      " " +
      clearPhone +
      " (" +
      this.companyPhoneCountry.iso2 +
      ")";
  }
  @Watch("user.password")
  @Watch("verifyUserPassword")
  verifyEqualsPasswords(): void {
    if (
      this.user.password == this.verifyUserPassword &&
      this.user.password != "" &&
      this.verifyUserPassword != "" &&
      this.user.password != null &&
      this.verifyUserPassword != null
    ) {
      this.passwordValidations.equals = true;
    } else {
      this.passwordValidations.equals = false;
    }
  }

  @Watch("user.password")
  verifyMinLength(value: string): void {
    if (value != null) {
      if (value.length >= process.env.VUE_APP_MIN_LENGTH_PASSWORD) {
        this.passwordValidations.minLength = true;
      } else {
        this.passwordValidations.minLength = false;
      }
    }
  }

  @Watch("user.password")
  verifyMinCapitalLetter(value: string): void {
    if (/[A-Z]/.test(value)) {
      this.passwordValidations.minCapitalLetter = true;
    } else {
      this.passwordValidations.minCapitalLetter = false;
    }
  }

  @Watch("user.password")
  verifyMinNumber(value: string): void {
    if (/[0-9]/.test(value)) {
      this.passwordValidations.minNumber = true;
    } else {
      this.passwordValidations.minNumber = false;
    }
  }

  @Watch("user.password")
  verifyNoEmptySpaces(value: string): void {
    if (/[ \t\r\n\f]/.test(value)) {
      this.passwordValidations.noEmptySpaces = false;
    } else {
      this.passwordValidations.noEmptySpaces = value != null ? true : false;
    }
  }

  @Watch("user.password")
  verifyMinSpecial(value: string): void {
    if (/[#$%&/=*!¡?¿]/.test(value)) {
      this.passwordValidations.minSpecial = true;
    } else {
      this.passwordValidations.minSpecial = false;
    }
  }

  @Watch("user.password")
  @Watch("verifyUserPassword")
  hideErrorPassword(): void {
    if (this.checkUserPassword()) {
      this.showErrorPassword = false;
    } else {
      this.showErrorPassword = true;
    }
  }

  @Watch("system.logo.file")
  previewLogo(file: File): void {
    if (file != null) {
      this.system.logo.preview = URL.createObjectURL(file);
    } else {
      this.system.logo.preview = "";
      this.system.logo.file = null;
    }
  }

  @Watch("system.carousel.files")
  previewCarousel(files: Array<File>): void {
    if (files.length > 0) {
      files.forEach((file: File) => {
        this.system.carousel.filesPreview.push(URL.createObjectURL(file));
      });
    } else {
      this.system.carousel.filesPreview = [];
    }
  }

  private async created(): Promise<void> {
    await this.$store
      .dispatch("system/getInitialDataForEveryone")
      .then(async (response) => {
        this.$store.dispatch(
          "settings/getFirstStepsRegisteredByInitialData",
          response.first_steps_check
        );
      });
    await this.getColors();
    const firstStepsRegistered =
      this.$store.getters["settings/getFirstStepsRegistered"];

    if (firstStepsRegistered) {
      this.$router.push({ path: "/home" });
    } else {
      await this.$store.dispatch("settings/getSecuritySession");
    }
  }

  private async getColors() {
    const storedColors = await this.$store.getters["styles/getColors"];
    if (storedColors.length) {
      storedColors.forEach((color) => {
        if (color.mode == "LIGHT") {
          this.system.styles.colors.light.push({
            value: color.value,
            level: color.level,
          });
        } else {
          this.system.styles.colors.dark.push({
            value: color.value,
            level: color.level,
          });
        }
      });
    }
  }

  private checkUserPassword(): boolean {
    if (
      this.passwordValidations.minLength &&
      this.passwordValidations.minSpecial &&
      this.passwordValidations.minNumber &&
      this.passwordValidations.minCapitalLetter &&
      this.passwordValidations.noEmptySpaces &&
      this.passwordValidations.equals
    ) {
      return true;
    } else {
      return false;
    }
  }

  private selectedCountry(country: any): void {
    this.phoneCountry = country;
    this.formatedPhone =
      "+" +
      this.phoneCountry.dialCode +
      " " +
      (this.user.phone == null ? "" : this.user.phone) +
      " (" +
      this.phoneCountry.iso2 +
      ")";
  }
  selectedCompanyCountry(country: any): void {
    this.companyPhoneCountry = country;
    this.companyPhone =
      "+" +
      this.companyPhoneCountry.dialCode +
      " " +
      (this.company.phone == null ? "" : this.company.phone) +
      " (" +
      this.companyPhoneCountry.iso2 +
      ")";
  }
  private moveToStep(step: number) {
    switch (step) {
      case 2:
        if (
          this.$refs.firstStepForm.validate() &&
          this.company.description != ""
        ) {
          this.step = this.step + 1;
        }

        if (this.company.description == "") {
          this.showErrorDescription = true;
        }
        break;
      case 3:
        if (this.$refs.secondStepForm.validate()) {
          this.step = this.step + 1;
        }
        break;
      case 4:
        if (this.$refs.thirdStepForm.validate() && this.checkUserPassword()) {
          this.step = this.step + 1;
        }
        break;
      case 5:
        this.step = this.step + 1;
        break;
      case 6:
        if (this.$refs.fourthStepForm.validate()) {
          this.step = this.step + 1;
        }
        break;

      default:
        break;
    }
  }

  private async startSystemSetUp(): Promise<void> {
    this.loading = true;
    this.user.phone = this.formatedPhone;
    this.company.phone = this.companyPhone;
    const payloadFirstSteps = new FormData();
    payloadFirstSteps.append(
      "LOGO",
      this.system.logo.file == null ? "" : this.system.logo.file
    );
    payloadFirstSteps.append(
      "DATA",
      JSON.stringify({ company: this.company, user: this.user })
    );

    this.systemSetUpLoading.company.loading = true;
    await this.$store
      .dispatch("settings/setFirstSteps", payloadFirstSteps)
      .then(() => {
        this.systemSetUpLoading.company.loading = false;
        this.systemSetUpLoading.company.check = true;
        this.setStyles();
      })
      .catch((error) => {
        const Error: SystemError = {
          date: this.currentDate(),
          path: this.$route.fullPath,
          errorTraceback: error.stack,
          errorMessage: error.message,
          criticalError: true,
          description: this.$tc("Views.fs-e1"),
        };

        this.$store.dispatch("notifications/showSystemError", Error);
      });
  }

  private async setStyles() {
    this.systemSetUpLoading.styles.loading = true;
    const payloadStyles: any[] = [];
    this.system.styles.colors.light.forEach((style) => {
      payloadStyles.push({
        value: style.value,
        level: style.level,
        mode: "LIGHT",
        type: "COLOR",
      });
    });
    this.system.styles.colors.dark.forEach((style) => {
      payloadStyles.push({
        value: style.value,
        level: style.level,
        mode: "DARK",
        type: "COLOR",
      });
    });

    await this.$store
      .dispatch("styles/setStyles", { styles: payloadStyles })
      .then(async () => {
        const storedColors = await this.$store.getters["styles/getColors"];
        if (storedColors.length) {
          storedColors.forEach((color) => {
            this.setColor(color);
          });
        }
      })
      .catch((error) => {
        const Error: SystemError = {
          date: this.currentDate(),
          path: this.$route.fullPath,
          errorTraceback: error.stack,
          errorMessage: error.message,
          criticalError: false,
          description: this.$tc("Views.fs-e2"),
        };

        this.$store.dispatch("notifications/showSystemError", Error);
      })
      .finally(() => {
        this.styleProgress = 50;
        this.setMode();
      });
  }

  private async setMode() {
    let payloadMode: { status: string; name: string; id: number } = {
      status: "",
      name: this.$constants.FUNCTIONALITY.DARK_MODE,
      id: 0,
    };
    const functionalities = await this.$store.getters[
      "functionalities/getFunctionalities"
    ];
    functionalities.forEach((functionality) => {
      if (functionality.name == this.$constants.FUNCTIONALITY.DARK_MODE) {
        payloadMode.id = functionality.id;
      }
    });

    if (this.mode == "LIGHT") {
      payloadMode.status = this.$constants.STATUS.INACTIVE;
    } else {
      payloadMode.status = this.$constants.STATUS.ACTIVE;
    }

    await this.$store
      .dispatch("functionalities/setFunctionalityStatus", payloadMode)
      .catch((error) => {
        const Error: SystemError = {
          date: this.currentDate(),
          path: this.$route.fullPath,
          errorTraceback: error.stack,
          errorMessage: error.message,
          criticalError: false,
          description: this.$tc("Views.fs-e3"),
        };

        this.$store.dispatch("notifications/showSystemError", Error);
      })
      .finally(() => {
        this.systemSetUpLoading.styles.loading = false;
        this.systemSetUpLoading.styles.check = true;
        this.styleProgress = 100;
        this.setSystemCarousel();
      });
  }

  private async setSystemCarousel() {
    this.systemSetUpLoading.images.loading = true;
    const payloadCarousel = new FormData();
    this.system.carousel.files.forEach((file) => {
      payloadCarousel.append("CAROUSEL", file);
    });

    await this.$store
      .dispatch("settings/setCarousel", {
        files: payloadCarousel,
        firstSteps: true,
      })
      .then(async (response) => {
        this.deleteSession(response.data.session);
        await this.$store.dispatch("system/getSystemInfo");
        this.systemSetUpLoading.images.loading = false;
        this.systemSetUpLoading.images.check = true;
      })
      .catch((error) => {
        const Error: SystemError = {
          date: this.currentDate(),
          path: this.$route.fullPath,
          errorTraceback: error.stack,
          errorMessage: error.message,
          criticalError: false,
          description: this.$tc("Views.fs-e4"),
        };

        this.$store.dispatch("notifications/showSystemError", Error);
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
          info.removeData();
          this.navigate("/home");
        }, 3000);
      });
  }

  private deleteSession(name: string) {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  private get logoProgress(): boolean {
    return this.$store.getters["settings/getLogoProgress"];
  }

  styleProgress = 0;

  private get carouselProgress(): boolean {
    return this.$store.getters["settings/getCarouselProgress"];
  }

  setColor(color: any) {
    if (color.mode == "LIGHT") {
      this.$vuetify.theme.themes.light[color.level.toLowerCase()] = color.value;
    } else {
      this.$vuetify.theme.themes.dark[color.level.toLowerCase()] = color.value;
    }
  }

  getContrast(mode: "LIGHT" | "DARK") {
    let primary = "";
    let colors =
      mode == "LIGHT"
        ? this.system.styles.colors.light
        : this.system.styles.colors.dark;
    colors.forEach((color) => {
      if (color.level == "PRIMARY") {
        primary = color.value;
      }
    });

    return { "background-color": primary };
  }

  textContrast(mode: "LIGHT" | "DARK") {
    let primary = "";
    let colors =
      mode == "LIGHT"
        ? this.system.styles.colors.light
        : this.system.styles.colors.dark;
    colors.forEach((color) => {
      if (color.level == "PRIMARY") {
        primary = color.value;
      }
    });
    let hexcolor = primary;
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black--text" : "white--text";
  }

  private get availableColors(): any[] {
    const colors = this.system.styles.colors.light;

    const available = colors.filter((color) => {
      if (color.level == "PRIMARY" || color.level == "SECONDARY") return color;
    });

    return available;
  }
}
